<template>
  <div>
    <!-- Featured image -->
    <div class="relative flex w-full h-auto group" :class="{'flex-row': isModal}">
      <template v-if="!galleryLoading && galleryData">
        <!-- Featured picture -->
        <template v-if="galleryData[0] && profileData.hasPictureFeatured && featuredPicture">
          <div class="w-full" @click="index = getFeaturedPictureIndex(featuredPicture.id)">
            <Picture
              loader-enabled
              :key="featuredPicture.id"
              :src="featuredPicture.squareFaceUrl"
              alt="profile picture"
              classes="border border-grayedHR rounded w-full aspect-square object-cover group-hover:brightness-50 cursor-pointer filter group-hover:grayscale group-hover:contrast-200 transition"
            />
            <div class="hidden group-hover:block cursor-pointer
              absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
            >
              <base-icon :width="48" :height="48" name="zoom">
              </base-icon>
            </div>
          </div>
        </template>
        <!-- No featured picture -->
        <template v-else>
          <div
            @click="isUserOwner ? openGalleryModal(true) : null"
            class="relative rounded bg-white w-full object-cover filter aspect-square border border-grayedHR h-picture-featured-guest"
            :class="{' hover:opacity-70 cursor-pointer transition-all duration-200': isUserOwner}"
          >
            <base-icon
              name="profilePictureFilledBlack"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              :width="175" :height="175"
            ></base-icon>
          </div>
        </template>
      </template>
    </div>
    <!-- Gallery pictures -->
    <div v-if="!galleryLoading" class="w-full gap-1.5 lg:gap-3 grid grid-cols-3 flex-wrap items-start"
      :class="{'mt-1.5 lg:mt-0 ml-0 lg:ml-3': isModal, 'mt-1.5 lg:mt-3 mb-3 ': !isModal}"
    >
      <div
        v-for="(picture, picIndex) in galleryData" :key="picture.id"
        class="flex flex-row" :class="{'items-start flex-none': isModal}"
      >
        <div @click="index = picIndex" class="w-full">
          <div class="relative w-full aspect-square rounded group overflow-hidden">
            <Picture
		          loader-enabled
              :src="picture.squareUrl"
              classes="w-full aspect-square rounded border border-grayedHR
                object-cover filter transition cursor-pointer
                group-hover:brightness-50 group-hover:grayscale group-hover:contrast-100 group-hover:filter"
              :class="[`${getPictureFilter(picture.status.slug)}`]"
            />
	          <div
			          class="w-full absolute cursor-pointer top-0 left-0 text-white text-s uppercase"
			          :class="[`box-gradient filter ${getPictureFilter(picture.status.slug)}`]"
	          >
	          </div>
            <div
                class="opacity-0 group-hover:opacity-100 transition absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10"
            >
              <base-icon :width="26" :height="26" name="zoom">
              </base-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isModal && !galleryLoading" class="flex flex-row w-full justify-end">
      <Button
        class="text-s inline-flex items-center"
        variant="link"
        v-show="isUserOwner"
        @clicked="openGalleryModal(true)"
        icon="gallery"
        :text="trans('BUTTON:EditPictures')"
      />
    </div>

    <Modal
      v-if="isShowGalleryModal"
      :showModal="isShowGalleryModal"
      hideFooter
      :variant="getGalleryVariant('GalleryModal')"
      @clicked="openGalleryModal(false)"
      @onConfirm=""
    >
      <template v-slot:header>
        <div class="flex flex-row gap-2 items-center mb-3">
          <span>
            {{ trans('MODAL_TITLE:GalleryEdition') }}
          </span>
          <Button
            v-if="galleryData.length > 0"
            variant="link"
            @clicked="toggleHint"
          >
            <base-icon  name="info">
            </base-icon>
          </Button>
        </div>
        <Hint variant="Info" v-if="galleryData.length === 0 || isHintDisplayed" class="my-3 font-normal">
          <div class="w-full flex flex-col lg:flex-row gap-3 lg:gap-0">
            <div class="lg:w-1/2" v-html="trans('TOOLTIP:GalleryInformation1')"></div>
            <div class="lg:w-1/2" >
              <div class="w-full flex flex-col gap-3">
                <div v-html="trans('TOOLTIP:GalleryInformation2')"></div>
                <div v-html="transChoice('TOOLTIP:GalleryInformation3', configData.maxImagesAmount)"></div>
              </div>
            </div>
          </div>
        </Hint>
      </template>
      <template v-slot:content>
        <GalleryEdition
          :galleryData="galleryData"
          :is-user-owner="isUserOwner"
          @open-lightbox="openLightBoxGallery"
          @profile-picture-set="isProfilePictureSet = true"
        >
        </GalleryEdition>
      </template>
    </Modal>
    <CoolLightBox
      :effect="'fade'"
      :slideshow="false"
      :items="galleryLightbox"
      :index="index"
      srcMediaType="image"
      :close-on-click-outside-mobile="true"
      @close="index = null">
    </CoolLightBox>

  </div>
</template>

<script>
import AddPictureButton from "@/views/content/profile/components/gallery/components/AddPictureButton";
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import GalleryEdition from "@/views/content/profile/components/gallery/GalleryEdition";
import ImageModal from "@/utils/modal/ImageModal";
import Modal from "@/utils/modal/Modal";
import Picture from "@/utils/pictures/Picture";
import themeConfig from "@/themeConfig";
import {getMobileGalleryWidth, getPictureFilter} from "@/common/helpers/utils";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Loader from "@/views/Loader.vue";
import Hint from "@/views/content/components/Hint"
import {getGalleryVariant} from "@/common/helpers/utils";


export default {
  name: "GalleryProfile",
  components: { Button, ImageModal,  BaseIcon, Picture, AddPictureButton, GalleryEdition, Modal, Loader, Hint},
  props: {
    userData: Object,
    isUserOwner: Boolean,
	  isModal: {
			default: false,
		  type: Boolean,
	  }
  },
  data() {
    return {
      unionIcon: themeConfig.icons.union,
      isHintDisplayed:false,
      isShowGalleryModal: false,
      isShowImageModal: false,
      openedImage: null,
      getPictureFilter: getPictureFilter,
      getMobileGalleryWidth:getMobileGalleryWidth,
      index: null,
      galleryLightbox: [],
      profilePlaceholder: themeConfig.app.profilePlaceholder,
      getGalleryVariant:getGalleryVariant,
	    isProfilePictureSet: false,
    }
  },
  watch: {
    galleryData: {
      deep: true,
      immediate: true,
      handler(gallery) {
        this.galleryLightbox = [];
        if(gallery && gallery.length > 0) {
          gallery.filter(el => {
            this.galleryLightbox.push(typeof(el.url) === 'function' ? el.url() : el.url)
          });
						let additionalPicture = this.userData.hasPictureFeatured ? 1 : 0;
		        this.setPicturesLoadingCounter(this.galleryData.length + additionalPicture)
		        if(this.isShowGalleryModal && !this.isProfilePictureSet) {
			        this.setPicturesLoadingCounter(this.galleryData.length);
		        }
						if(this.isProfilePictureSet) {
							this.setPicturesLoadingCounter(null);
							this.isProfilePictureSet = false;
						}
        }
      }
    },
  },
  computed: {
    ...mapGetters('gallery', ['galleryData', 'gallerySourcesArray','galleryLoading']),
    ...mapGetters('profile', ['profileData', 'profileLoaded']),
    ...mapGetters('lang', ['configData']),
	  ...mapGetters('utils', ['picturesLoadingCounter']),
    featuredPicture() {
      if(this.galleryData) {
        return Object.values(this.galleryData).find(el => el.isFeatured);
      }
    },
  },
  methods: {
    ...mapActions('gallery', ['fetchUserGallery']),
	  ...mapMutations('utils', ['setPicturesLoadingCounter']),

    additionBlocked() {
      return Object.keys(this.galleryData).length === this.configData.maxImagesAmount
    },
    openGalleryModal(value) {
      this.isShowGalleryModal = value;
    },
    getFeaturedPictureIndex(id) {
      return this.galleryData.findIndex(el => el.id === id);
    },
    openLightBoxGallery(index) {
      this.index = index
    },
    toggleHint() {
      this.isHintDisplayed = !this.isHintDisplayed;
    },

  },
  mounted() {
  }
}
</script>
